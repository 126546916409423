.form-control.input-search {
    height: 38px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.input-search {
    height: 38px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #eee;
    padding: 0 8px;
}

.filtering-table thead th:first-child {
    width: 70px;
}

.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #EEEEEE;
}

.filter-pagination {
    display: inline-block;
    background: #e1def1;
    border-radius: 10px;
    padding: 5px 5px;
}

[data-theme-version="dark"] .filter-pagination {
    background: #2c254a !important;
}

.filter-pagination .previous-button,
.filter-pagination .next-button {
    font-size: 12px;
    border: 0 !important;
    width: auto;
    padding: 4px 8px;
    margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #3b3363;
    color: #fff;
}

.filter-pagination button:hover {
    background: #3b3363;
    color: #fff;
}

.filter-pagination button[disabled] {
    opacity: 0.5;
    cursor: default;
}

.filter-pagination button[disabled]:hover {
    background: #3b3363 !important;
    opacity: 0.5;
    color: #fff;
}

.table-index input[type=number] {
    background: #e0e0f1 !important;
    color: #111010;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
    border-radius: 8px;
}

[data-theme-version="dark"] .table-index input[type=number] {
    background: #2c254a !important;
    color: #fff;
}

@media only screen and (max-width: 575px) {

    .filter-pagination .previous-button,
    .filter-pagination .next-button {
        padding: 8px 10px;
        font-size: 14px;
    }
}

.tbl-heading-txt {
    margin-bottom: 50px;
}