.purchase-form .form-control {
    padding: 4px;
    height: 37px;
}

.my-react-select-container {
    padding-left: 1px;
    padding-right: 1px;
}

.purchase-1,
.purchase-2,
.purchase-3 {
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 5px;
}

.tax-txt {
    color: rgb(166, 244, 245);
    padding: 5px;
}

.udf-select {
    padding-left: unset !important;
}

.table-purchase-tr {
    border: unset !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.table-purchase-span {
    font-size: 12px;
}

.table-purchase-label {
    margin: unset !important;
}

.table-purchase-td {
    padding: unset !important;
}

.star-txt {
    font-size: 16px;
}

.text-area-udf {
    height: 100% !important;
}

.text-area-udf1 {
    height: 70% !important;
}

.stock-transfer .form-control {
    padding: 8px;
    height: 37px;
}

.stock-transfer-btn {
    margin-top: '80px'
}

.pr-6 {
    padding-right: 2rem !important;
}

[data-theme-version="dark"] [type=date] {
    color-scheme: dark;
}

[data-theme-version="light"] .form-control {
    /* border-color: rgba(174, 169, 169, 0.923); */
    border-color: rgba(194, 188, 188, 0.923) !important;
}

.salesman-no-print {
    background: transparent;
    border: 1px solid #5b5289;
    border-color: #5b5289;
    color: #6e6e6e;
    border-radius: 0.35rem;
    height: auto;
    width: 55%;
    font-weight: 300;
}

[data-theme-version="dark"] .salesman-no-print {
    color: #d6d4d4;
}

.text-end {
    text-align: end;
}

.fixed-column {
    position: sticky;
    z-index: 1;
    right: 0;
}

.btn-icon {
    padding: 2px 5px !important;
}

.wd-100 {
    width: 100px !important;
}

.udf-pass-icon {
    cursor: pointer;
}

.currentStock {
    display: flex;
    justify-content: space-between;
}

.stock-transfer-span {
    margin-left: 3px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.mr-px2 {
    margin-right: 2px;
}

.p-16 {
    padding: 16px !important;
}

.discount_per {
    padding-right: 2%;
}

.logo-text-color {
    color: #af5e3a;
}

.logo-text-sm {
    size: 10px;
}

.cus-form-control {
    padding: 5px !important;
    height: 35px !important;
}

.ph-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

tbody tr {
    border-collapse: collapse !important;
    border-bottom: 1px !important;
    border-top: 1px !important;
}

.udfn-rupee-icon {
    color: whitesmoke !important;
    font-size: 30px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.purchase-reprint {
    background: transparent;
    border: 1px solid #5b5289;
    border-color: #5b5289;
    color: #6e6e6e;
    border-radius: 0.35rem;
    width: 40%;
    font-weight: 300;
}

.udfn-eye-icon:hover {
    cursor: pointer;
  }



  
.contentToPrint hr{
    margin: 6px 0px;
    color:black !important;
    border-color: black !important;
}





/* .contentToPrint .printheader,.contentToPrint .header-space
  {
    height: 150px;
    margin: 0px 0px;
  } */
  .contentToPrint .printheader {
    /* position: fixed; */
    /* top: 20; */
    /* height: 200px; */
    /* height: auto; */
    padding: 8px;
  }
  /* .contentToPrint .footer-space {
height: 300px;
  } */
  /* .contentToPrint.printfooter, .contentToPrint .footer-space {
    height: 100px;
 
  } */

  .contentToPrint .printheader, .contentToPrint .header-space
  {
    top: 20;
    height: 203px;
    display: flex;
    flex-direction: column;
    padding-top: 20;
  
   
  
  }
  /* .printfooter, .footer-space
  {
    height: 220px;
  } */
  .contentToPrint div{
    line-height: 15px;
  }
  .contentToPrint tbody td{
   
    padding: 4px 16px;
  
  }
  /* .contentToPrint
  {
    height: auto;
    min-height: 210mm !important;
  } */
 
   .printfooter {
    /* bottom: 0px !important;
    display: block;
   
  
    bottom: 0;
    margin-bottom: 0; */


  }

.h_line {
    border-bottom: 1px solid black;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
       border-width: 1px;
}




.purchsecontentToPrint hr{
    margin: 6px 0px;
    color:black !important;
    border-color: black !important;
}


  .purchsecontentToPrint .printheader {   
    padding: 8px;
  }
  

  .purchsecontentToPrint .printheader
  {
    /* top: 20; */
    height: auto;
    display: flex;
    flex-direction: column;
    /* padding-top: 20;
   */
   
  
  }

  .purchsecontentToPrint div{
    line-height: 15px;
  }
  .purchsecontentToPrint tbody td{
   
    padding: 4px 16px;
  
  }


.purchsecontentToPrint .h_line {
    border-bottom: 1px solid black;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
       border-width: 1px;
}




.purchsecontentToPrint .theader, .purchsecontentToPrint .theader-space,
.purchsecontentToPrint .tfooter, .purchsecontentToPrint .tfooter-space {
  height: 100px;
}
.purchsecontentToPrint .theader {
  position: fixed;
  top: 0;
}
.purchsecontentToPrint .tfooter {
  position: fixed;
  bottom: 0;
}


@media print {
    .purchsecontentToPrint .print-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100px; /* Adjust height as needed */
      background-color: #f0f0f0; /* Adjust background color as needed */
      border-bottom: 1px solid #ccc; /* Optional: Add border */
  
    }
    .purchsecontentToPrint .page-break {
        page-break-after: always;
      }
  }
  .purchsecontentToPrint .print-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100px; /* Adjust height as needed */
    background-color: #f0f0f0; /* Adjust background color as needed */
    border-bottom: 1px solid #ccc; /* Optional: Add border */

  }
  




  
  
