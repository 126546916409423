
.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border-radius: $radius;
    border: 1px solid $light;
    height: 40px;
    background: $white;
	@at-root [data-theme-version="dark"] & {
        background:$d-bg;
		border-color:$d-border;
    }
		
		
    &:hover,&:focus,&.active{
        box-shadow: none;
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
	color:$body-color;
	padding-left: 15px;
	min-height: 40px;
}

.select2-container--default .select2-selection--multiple {
    border-color: $border;
    border-radius: 0;
	
}

.select2-dropdown {
    border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: $border;
    background: $white;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
    right: 15px;
}
.select2-container .select2-selection--multiple{
	min-height: 40px;
	color:$body-color;
	border-radius: $radius;
	border: 1px solid $light;
	@at-root [data-theme-version="dark"] & {
        background:$d-bg;
		border-color:$d-border;
    }
}
.select2-search--dropdown .select2-search__field{
	@at-root [data-theme-version="dark"] & {
        background:$dark-card;
		border-color:$d-border;
    }
}
.select2-dropdown{
	border-color: $light;
	@at-root [data-theme-version="dark"] & {
        background:$d-bg;
		border-color:$d-border;
    }
}
.swal2-popup .swal2-content{
	color:$body-color;
}
/*  css-1s2u09g-control
  css-b62m3t-container
   css-1hb7zxy-IndicatorsContainer
   
   my-react-select-container css-b62m3t-container

 css-x19aeh-control */
[data-theme-version="dark"] {
	.css-b62m3t-container{
		& > div{
			border-color: rgba(255,255,255,0.3);
			background: #2C254A;
		}
		[class*="-singleValue"],
		[class*="-placeholder"]{
			color:#fff;
		}
		[class*="-multiValue"]{
			background:rgba(255,255,255,0.1);
			div{
				color:#fff;
			}
		}
		[class*="-IndicatorsContainer"]{
			div{color:#fff; }
		}
		[class*="-menu"]{
			background:#18132D;
			& > div > *{
				&:hover{
					background:rgba(255,255,255,0.1) ;
				}
			}
		}
		
	}
	.sidebar-right {
		.tab-content{
			background:#3b3363;			
			.tab-pane .admin-settings p{
				color:#fff;
			}
		}
		.sidebar-right-inner .admin-title{
			color:#fff !important;
		}
		.card-tabs .nav-tabs {
			background:rgba(255,255,255,0.1);
			.nav-item .nav-link{
				background:rgba(255,255,255,0.1);
				color:#fff;
				&.active{
					background:rgba(255,255,255,0.25);
				}
			}
		}
	}
}
.css-x19aeh-control{

}